<template>
  <div style="display:flex;justify-content:flex-end;padding:5px;flex-wrap:wrap">
    <button type="button" class="btn btn-sm btn-default mr-1" style="pointer-events:auto" @click.prevent="cancel">取消</button>
    <div class="btn-group" style="pointer-events:auto" role="group">
      <button type="button" class=" btn btn-sm btn-default mr-1 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        视角
      </button>
      <div class="dropdown-menu">
        <input type="button" class="dropdown-item" value="常规视角" @click="view_normal()" />
        <input type="button" class="dropdown-item" value="鱼眼视角" @click="view_fisheye()" />
        <input type="button" class="dropdown-item" value="立体视角" @click="view_stereographic()" />
        <input type="button" class="dropdown-item" value="构架视角" @click="view_architectural()" />
        <input type="button" class="dropdown-item" value="超广角视角" @click="view_pannini()" />
        <input type="button" class="dropdown-item" value="小行星视角" @click="view_littleplanet()" />
      </div>
    </div>
    <button type="button" class="btn btn-sm btn-default mr-1" style="pointer-events:auto" @click="resetPanoView()">复位</button>
    <button type="button" class="btn btn-sm btn-primary mr-1" style="pointer-events:auto" @click="savePanoCover()">保存全景封面</button>
    <button type="button" class="btn btn-sm btn-primary mr-1" style="pointer-events:auto" @click="saveTourCover()">保存漫游封面</button>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
      }
    },
    watch: {
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      pano() {
        return this.publicData.pano || {}
      },
      form() {
        return this.publicData.form || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          PanoCoverChange: {
            target: 'Data',
            name: 'setPanoCover',
          },
          TourCoverChange: {
            target: 'Data',
            name: 'setTourCover',
          },
        },
        funcs: {
          resetPanoView: this.resetPanoView,
          savePanoCover: this.savePanoCover
        }
      })
    },
    destroyed() {
    },
    methods: {
      resetPanoView() {
        this.getFunc({
          target: 'FullPano',
          name: 'tweenView'
        })({
          fov: this.pano.fov,
          hlookat: this.pano.hLookAt,
          vlookat: this.pano.vLookAt,
        })
        this.view_normal()
      },
      savePanoCover() {
        this.getFunc({ target: 'FullPano', name: 'makeScreenshot' })(this.UploadPanoCover)
      },
      saveTourCover() {
        this.getFunc({ target: 'FullPano', name: 'makeScreenshot' })(this.UploadTourCover)
      },
      UploadPanoCover(blob) {
        this.UpdateCover(blob, this.urls.panoCover({ panoId: this.pano.guid }), 'PanoCoverChange')
      },
      UploadTourCover(blob) {
        this.UpdateCover(blob, this.urls.tourCover({ tourId: this.form.guid }), 'TourCoverChange')
      },
      cancel() {
        this.getFunc(
          {
            name: 'renewSence',
            arg: 'default'
          }
        )()
      },
      view_normal() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_normal'
            }
          }
        )()
      },
      view_fisheye() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_fisheye'
            }
          }
        )()
      },
      view_stereographic() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_stereographic'
            }
          }
        )()
      },
      view_architectural() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_architectural'
            }
          }
        )()
      },
      view_pannini() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_pannini'
            }
          }
        )()
      },
      view_littleplanet() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_littleplanet'
            }
          }
        )()
      },
      UpdateCover(blob, url, event) {
        if (this.updateing) {
          return
        }
        this.updateing = true;
        if (blob) {
          let data = new FormData();
          //data.append('ArticleGUID', this.form.guid);
          data.append('file', blob, "Cover.jpg");
          var config = {
            url: url,
            method: 'POST',
            data: data,
          }
          this.$axios.request(config)
            .then((response) => {
              console.log('#', response)
              //this.$http.put('/Api/My/Panos/' + this.form.guid, this.form)
              //    .then(function (response) {
              //        this.Close()
              //        this.updateing = false;
              //    })
              //    .catch(function (error) {
              //        console.log(error)
              //        this.updateing = false;
              //    })
              //this.form.cover = response.data.cover
              this.$emit(event, response.data)
              this.updateing = false;
              this.$message({
                message: '保存成功',
                type: 'info',
                zIndex: 9999,
              })
              this.viewType = null
              this.$emit('updateComplete')
              //  this.resetPanoView()
            })
            .catch((error) => {
              console.error(error)
              this.updateing = false;
            })
        } else {
          console.error('缺少blob')
        }
      },
    },
  }
</script>
<style scoped>
</style>
